import './styles.css';
import React from 'react';

export default function TileViewItem(item) {

  let flagCountry = '';
  try { flagCountry = require('./asset/flags/' + item.data.countryCode + '.png'); } catch (ex) { }

  let flagCountryOfPurchase = '';
  try { flagCountryOfPurchase = require('./asset/flags/' + item.data.countryOfPurchaseCode + '.png'); } catch (ex) { }

  let image = '';
  try {
    image = require('./asset/img/' + item.data.id + '.png');
  } catch (ex) { 
    image = require('./asset/img/empty-bottle.png');
  }

  return (
    <div id={item.data.id} className='tile-view-item'>
        <div>
          <p className='product-name'>{item.data.name}</p>
          {item.data.originalPurchase === true &&
            <span className='country-purchase'>
              {'('}
              {`purchased in ${item.data.countryOfPurchase}`}
              <img className='flag-sm' alt={item.data.countryOfPurchase} src={flagCountryOfPurchase} />
              {')'}
            </span>
          }
          {image !== '' &&
            <p>
              <img className='bottle' alt={item.data.id} src={image} />
            </p>
          }
          <p className='flag'>
            <img className='flag-img' alt={item.data.country} src={flagCountry} />
            <span className='flag-txt' >{` ${item.data.country}`}</span>
          </p>
          <p className='product-style'>
            <span>{item.data.style}</span>
            {item.data.year !== '' &&
              <span>  (year {item.data.year})</span>
            }
          </p>
        </div>
        <div>
          <p className='product-volume'>
            <span>{item.data.volumeML} ml</span>
            {item.data.abv !== '' &&
              <span>  <strong>{item.data.abv} Alc. Vol.</strong></span>
            }
          </p>
          {item.data.url !== '' &&
            <p className='product-link'>
              <a href={item.data.url} target='_blank'>
                Product Link
              </a>
            </p>
          }
        </div>
      </div>
  );
}
