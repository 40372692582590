import './styles.css';

import React from 'react';
import TileViewTemplate from './TileViewTemplate.js';

export default function TabPanelItem({ data }) {
    return (
        <div className='tab-panel-item'>
            <TileViewTemplate
                key={data.group}
                data={data}
            />
        </div>
    );
}