import './styles.css';

import React from 'react';
import TileViewItem from './TileViewItem.js';
import TileView from 'devextreme-react/tile-view';

export default function TileViewTemplate({ data }) {

  let filteredItems = data.items.filter((item) => item.inStock);
  for (let i = 0; i < filteredItems.length; i++) {
    filteredItems[i].window = {
      width: data.window.width,
      height: data.window.height
    }
  }

  const height = data.window.height - (data.window.width < 600 ? 250 : 350);
  const baseItemHeight = data.window.width < 800 ? 450 : 550;
  const baseItemWidth = data.window.width < 800 ? 150 : 250;

  return (
    <div>
        <TileView
          className='tree-view-template'
          items={filteredItems}
          height={height}
          baseItemHeight={baseItemHeight}
          baseItemWidth={baseItemWidth}
          itemMargin={10}
          itemComponent={TileViewItem}
          direction='vertical'
          showScrollbar='always'
        />
    </div>
  );
}
