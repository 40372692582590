import './styles.css';

import React from 'react';

export default function TabPanelTitle({ data }) {
    return (
        <div className='tab-panel-title'>
            {data.group}{` (${data.inStockCount})`}
        </div>
    );
}
