import './styles.css';

import React from 'react';

export default function Footer() {
    return (
        <section className="copyright">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="copyright-info">
                            <p>
                              <a href="https://fredericpaladin.com" target="_blank">Frederic Paladin</a>
                              <span className="sep"> | </span> <span>Copyright © {(new Date()).getFullYear()}</span>
                            </p>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <ul className="socials pull-right"></ul>
                    </div>
                </div>
            </div>
        </section>
    );
}
