import './styles.css';
import 'devextreme/dist/css/dx.dark.css';

import React from 'react';
import TabPanel from 'devextreme-react/tab-panel';
import TabPanelTitle from './TabPanelTitle.js';
import TabPanelItem from './TabPanelItem.js';
import Footer from './Footer.js';
import data from './asset/data/data.json';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.logo = require('./asset/png/don-federico-logo-white.png');
    this.state = {
      innerWidth: 0,
      innerHeight: 0
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  resize() {
    this.setState({
      innerWidth: window.innerWidth,
      innerHeight: window.innerHeight
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  render() {
    const {
      innerWidth,
      innerHeight
    } = this.state;
    
    for (let i = 0; i < data.length; i++) {
      data[i].window = {
        width: innerWidth,
        height: innerHeight
      }
    }

    return (
      <div>
        <img className='logo' alt={'don-federico-club'} src={this.logo} />
        <div className='tab-panel'>
          <TabPanel
            className="dx-theme-background-color"
            width="100%"
            height={data.innerHeight}
            loop={false}
            deferRendering={false}
            animationEnabled={true}
            swipeEnabled={false}
            dataSource={data}
            tabsPosition='top'
            stylingMode='secondary'
            itemTitleComponent={TabPanelTitle}
            itemComponent={TabPanelItem}
            scrollByContent={false}
            showNavButtons={true}
          />
        </div>
        <Footer />
      </div>
    );
  }
}

export default App;